/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {useSelector, useDispatch} from 'react-redux'

import {v4 as uuid} from 'uuid'
import {useNavigate} from 'react-router-dom'
import {GET_COUNTRIES_CODE, GET_INITIAL_DATA} from '../../../GraphQLQuries/common'
import {GET_MPIN_LENGTH, WEB_ADMIN_LOGIN, WEB_ADMIN_LOGIN_PROD} from '../../../GraphQLQuries/Auth'
import useLazyQueryApi from '../../../../../hooks/useLazyQuery'
import {actionsUsers} from '../../../../../setup/redux/rootReducer'
import {checkMobileNoValid} from '../../../../../data/config'
import useMutationApi from '../../../../../hooks/useMutation'
import useApi from '../../../../../hooks/useQuery'
import {ToastMessageContainer} from '../../../../../_ePulse/layout/components/ToastMessageContainer'
import {toast} from 'react-toastify'
import {LoadingSpinner} from '../../../../../_ePulse/layout/components/LoadingSpinner'

const loginSchema = Yup.object().shape({
  // mobileNumber: Yup.number().required('Phone Number is required'),
  mobileNumber: Yup.string()
    .required('Phone Number is required')
    .matches(/^\d+$/, 'Phone Number must only contain digits')
    .test('is-valid-number', 'Phone Number must be a valid number', (value) => {
      if (!value) return false // Required check
      return !isNaN(Number(value)) // Ensure it's a valid number
    }),
  countryCode: Yup.number().required('Country is required').not(['default'], 'Select Country Code'),
})

const initialValues = {
  mobileNumber: '',
  loginType: 'MPIN',
  mpin: '',
  countryCode: '91',
}

export function Login() {
  const {userId, token}: any = useSelector((state) => {
    return state
  })

  const [loginType, setloginType] = useState<string>('MPIN')
  const [countryCodeValue, setcountryCodeValue] = useState('91')
  const [errorMessage, setErrorMessage] = useState('')
  const [unique_id, setuuid] = useState<any>()
  const [ip, setIP] = useState('')
  const [browserDetails, setBrowserDetails] = useState<any>(null)
  const dispatch = useDispatch()

  const [countryCode, setcountryCode] = useState<any>([])
  const [initialData, setinitialData] = useState<any>({})
  const browsingDetails = useSelector((state: any) => state.browsingDetails)

  useEffect(() => {
    if (browsingDetails.browserUniqueId) {
      setuuid(browsingDetails.browserUniqueId)
    } else {
      setuuid(uuid())
    }
  }, [browsingDetails])

  const {
    data,
    loading: loading4,
    error: error4,
    refetch: refetch4,
  } = useApi(true, GET_COUNTRIES_CODE, {})

  useEffect(() => {
    if (data) {
      const res = data?.getCountries
      setcountryCode(res)
    }
  }, [data])

  const {
    data: initialDatares,
    loading: initialDataloading,
    error: initialDataerror,
    refetch: initialDatarefetch,
  } = useApi(true, GET_INITIAL_DATA, {})

  useEffect(() => {
    if (initialDatares) {
      const res = initialDatares?.getInitialData
      setinitialData(res)
      if (res.isEnforceOtp) {
        handleLoginType('OTP')
      }
    }
  }, [initialDatares])

  const getData = async () => {
    const response = await fetch('https://api.ipify.org/?format=json')
    const data = await response.json()
    setIP(data.ip)
  }

  useEffect(() => {
    getData()
  }, [])

  const getMPINLength = async (values: any) => {
    fetchData({
      mobileNumber: values.mobileNumber.toString(),
      countryCode: parseInt(countryCodeValue),
      browserUniqueId: unique_id,
      ipAddress: ip,
      userAgent: browserDetails?.userAgent,
      browserName: browserDetails?.name,
      deviceType: 'web',
    })
  }

  const {
    fetchData,
    loading: loading3,
    error: error3,
    data: data3,
  } = useLazyQueryApi(true, GET_MPIN_LENGTH)

  useEffect(() => {
    if (data3) {
      if (data3.getMpinLengthFromMobileNumber.enforceOtpLoginWeb) {
        toast.error('Please use OTP login, As M-pin login is restricted!')
      } else {
        dispatch(
          actionsUsers.updateUser({
            mobile_number: formik.values.mobileNumber,
          })
        )
        dispatch(
          actionsUsers.updatebrowsingDetails({
            browserUniqueId: unique_id,
            ipAddress: ip,
            userAgent: browserDetails?.userAgent,
            browserName: browserDetails.name,
          })
        )

        navigate(`/auth/mpin`, {
          state: {
            browserUniqueId: unique_id,
            ipAddress: ip,
            userAgent: browserDetails?.userAgent,
            browserName: browserDetails.name,
            countryCode: formik.values.countryCode,
            mpinLength: data3?.getMpinLengthFromMobileNumber.mpinLength,
          },
        })
      }
    }
  }, [data3])

  useEffect(() => {
    const browser = {
      name: getBrowserName(),
      userAgent: navigator.userAgent,
      // version: getBrowserVersion(),
      // platform: getBrowserPlatform(),
    }
    setBrowserDetails(browser)
  }, [])

  const getBrowserName = () => {
    var sBrowser,
      sUsrAg = navigator.userAgent

    // The order matters here, and this may report false positives for unlisted browsers.

    if (sUsrAg.indexOf('Firefox') > -1) {
      sBrowser = 'Mozilla Firefox'
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
      sBrowser = 'Samsung Internet'
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
      sBrowser = 'Opera'
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf('Trident') > -1) {
      sBrowser = 'Microsoft Internet Explorer'
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf('Edge') > -1 || sUsrAg.indexOf('Edg') > -1) {
      sBrowser = 'Microsoft Edge'
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf('Chrome') > -1) {
      sBrowser = 'Google Chrome or Chromium'
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf('Safari') > -1) {
      sBrowser = 'Apple Safari'
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = 'unknown'
    }

    return sBrowser
  }

  const {
    createData,
    data: data1,
    loading: loading1,
    error: error1,
  } = useMutationApi(process.env.REACT_APP_ENV !== 'PROD' ? WEB_ADMIN_LOGIN : WEB_ADMIN_LOGIN_PROD)

  const [loadingLoader, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [status, setstatus] = useState(false)
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    // validate: (values) => {
    //   var errors: any = {}
    //   if (loginType === 'MPIN') {
    //     if (values.mpin === '') {
    //       errors.mpin = 'Enter Security PIN'
    //     }
    //   }
    //   return errors
    // },

    onSubmit: async (
      values
      // , {setStatus, setSubmitting}
    ) => {
      setLoading(true)
      if (loginType === 'OTP') {
        try {
          const mobileNumber = values.mobileNumber.toString()
          const res = await createData({
            mobileNumber,
            countryCode: parseInt(values.countryCode),
            browserUniqueId: unique_id,
            ipAddress: ip,
            userAgent: browserDetails?.userAgent,
            browserName: browserDetails?.name,
            type: loginType,
          })

          // alert(`Your OTP is ${res?.data?.webAdminLogin?.otp}`)
          if (process.env.REACT_APP_ENV !== 'PROD') {
            //OTP TOAST
            alert(`Your OTP is ${res?.data?.webAdminLogin?.otp}`)
          }
          dispatch(
            actionsUsers.updateUser({
              mobile_number: formik.values.mobileNumber,
            })
          )
          dispatch(
            actionsUsers.updatebrowsingDetails({
              browserUniqueId: unique_id,
              ipAddress: ip,
              userAgent: browserDetails?.userAgent,
              browserName: browserDetails.name,
            })
          )
          navigate(`/auth/otp`, {
            state: {
              resendOtpTime: res?.data?.webAdminLogin?.resendOtpTime,
              browserUniqueId: unique_id,
              ipAddress: ip,
              userAgent: browserDetails.userAgent,
              browserName: browserDetails.name,
              countryCode: formik.values.countryCode,
            },
          })
          setLoading(false)
        } catch (error: any) {
          saveAuth(undefined)
          setErrorMessage(error?.message)
          // setSubmitting(false)
          setLoading(false)
          setstatus(true)
        }
      } else {
        try {
          getMPINLength(values)

          setLoading(false)
        } catch (error) {
          saveAuth(undefined)
          setLoading(false)
          setstatus(true)
        }
      }
    },
  })

  const changeMobileNo = (value: any) => {
    const valid = checkMobileNoValid(value)
    if (valid) {
      formik.setFieldValue('mobileNumber', parseInt(value))
    }
  }

  const handleLoginType = (value: any) => {
    setloginType(value)
    formik.setFieldValue('loginType', value)
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
      onKeyDown={(e) => {
        if (e.key === 'Enter') console.log('Enter pressed')
      }}
    >
      <LoadingSpinner isLoading={loading1} />
      <ToastMessageContainer />
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign in to myePulse</h1>
      </div>
      {/* begin::Heading */}

      {status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{errorMessage}</div>
        </div>
      )}

      {/* begin::Form group */}
      {/* <div className='col form-block component-input component-input-multi-separator'>
        <span className='form-label required'>Phone Number</span>
        <select
          className='border-0'
          value={formik.values.countryCode}
          onChange={(e) => {
            formik.setFieldValue('countryCode', e.target.value)
          }}
          // {...formik.getFieldProps('countryCode')}
        >
          {countryCode?.map((item: any) => {
            return (
              <option key={item.id} value={item.phoneCode}>
                +{item.phoneCode}
              </option>
            )
          })}
        </select>
        <span className='input-separator'>-</span>
        <input
          type='text'
          className='form-control px-3'
          value={formik.values.mobileNumber}
          onChange={(e: any) => changeMobileNo(e.target.value)}
        />
      </div> */}
      <div className='form-group border-0'>
        {/* <label className='form-label'>Phone Number</label> */}
        <div className='d-flex flex-row form-control'>
          <select
            className=' border-0'
            value={formik.values.countryCode}
            onChange={(e) => {
              formik.setFieldValue('countryCode', e.target.value)
              setcountryCodeValue(e.target.value)
            }}
            // {...formik.getFieldProps('countryCode')}
          >
            {/* {countryCode?.map((item: any) => {
              return (
                <option key={item.id} value={item.phoneCode}>
                  +{item.phoneCode}
                </option>
              )
            })} */}

            <option key={'India'} value={91}>
              +91
            </option>
          </select>
          <input
            placeholder='Phone Number'
            className={clsx('form-control border-0')}
            value={formik.values.mobileNumber}
            type={'number'}
            onWheel={(e) => {
              e.preventDefault() // Prevent mouse wheel and touchpad scrolling
            }}
            // Disable scrolling when focused
            onFocus={(e) => e.target.addEventListener('wheel', (e) => e.preventDefault())}
            onChange={(e: any) => changeMobileNo(e.target.value)}
          />
        </div>

        {formik.touched.mobileNumber && formik.errors.mobileNumber && (
          <div className='fv-plugins-message-container'>
            <span className='form-error' role='alert'>
              {formik.errors.mobileNumber}
            </span>
          </div>
        )}
      </div>
      {formik.touched.countryCode && formik.errors.countryCode && (
        <div className='fv-plugins-message-container'>
          <span className='text-danger'>{formik.errors.countryCode}</span>
        </div>
      )}

      <div className='mt-3 mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Login Using</label>
        <div className='d-flex flex-row'>
          {!initialData.isEnforceOtp && (
            <div className='d-flex col  align-items-center border-0 px-4 py-4 rounded'>
              <input
                type='radio'
                value='MPIN'
                // {...formik.getFieldProps('loginType')}
                checked={loginType === 'MPIN'}
                onChange={(e: any) => handleLoginType(e.target.value)}
              />
              <div className='symbol symbol-30px'>
                <span className='mx-4 text-dark fs-6'>MPIN</span>
              </div>
            </div>
          )}
          <div className='d-flex col  align-items-center border-0 px-4 py-4 rounded'>
            <input
              type='radio'
              // {...formik.getFieldProps('loginType')}
              value='OTP'
              checked={loginType === 'OTP'}
              onChange={(e: any) => handleLoginType(e.target.value)}
            />
            <div className='symbol symbol-30px'>
              <span className='mx-4 text-dark fs-6'>OTP</span>
            </div>
          </div>
        </div>
        {/* {loginType === 'MPIN' && (
          <div className='form-group'>
            <label className='form-label'>MPIN</label>
            <div className='form-control'>
              <input
                placeholder='Enter Security PIN'
                {...formik.getFieldProps('mpin')}
                className={clsx('form-control border-0')}
                type='text'
                name='mpin'
                maxLength={8}
              />
            </div>

            {formik.touched.mpin && formik.errors.mpin && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger'>{formik.errors.mpin}</span>
              </div>
            )}
          </div>
        )} */}
      </div>
      {/* end::Form group */}
      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          // className='btn btn-lg  w-100 mb-5 rounded-pill text-white'
          className='ePulse-btn-primary'
          disabled={formik.values.mobileNumber.toString().length !== 10}
          style={{backgroundColor: '#000FD0'}}
        >
          {!loadingLoader && <span className='indicator-label'>Continue</span>}
          {loadingLoader && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
