import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_ePulse/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_ePulse/layout/core'
import {MasterInit} from '../_ePulse/layout/MasterInit'
import {onError} from '@apollo/client/link/error'
import {createHttpLink, ApolloProvider, ApolloClient, InMemoryCache, from} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {useSelector} from 'react-redux'
import {AuthInit} from './common/modules/auth'
import {useDispatch} from 'react-redux'
import {actionsUsers} from '../setup/redux/rootReducer'
import {toast} from 'react-toastify'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
let dataUser: any = ''

export const checkPermission = (value: any) => {
  const allowed = dataUser?.auth?.userPermissions.includes(value)
  return allowed
  // return true
}

const App = () => {
  dataUser = useSelector((state) => {
    return state
  })

  // const client = new ApolloClient({
  //   uri: 'https://dev-api.myepulse.in/trulyGraphql',
  //   cache: new InMemoryCache(),
  // })

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URI,
  })

  const authLink = setContext((_, {headers}) => {
    // Get the authentication token from wherever you have it stored

    // Return the headers to the context so httpLink can read them

    return {
      headers: {
        ...headers,
        authorization: dataUser?.auth?.token ? `Bearer ${dataUser.auth.token}` : null,
        systemName: 'web',
      },
    }
  })

  const dispatch = useDispatch()
  const sessionExpirePattern = /session\s*expire/i

  let isToastVisible = false // Flag to track toast visibility

  const showErrorToast = (message: any) => {
    if (!isToastVisible) {
      isToastVisible = true
      toast.error(message, {
        onClose: () => {
          isToastVisible = false // Reset flag when toast closes
        },
      })
    }
  }

  const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({message, locations, path}) => {
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        // toast.error(message)
        showErrorToast(message)
        if (sessionExpirePattern.test(message)) {
          setTimeout(() => {
            dispatch(actionsUsers.logout())
          }, 3000)
        }
      })
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`)
      // toast.error(`${networkError}`)
    }
  })

  const client = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    // link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return (
    <ApolloProvider client={client}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </ApolloProvider>
  )
}

export {App}
