/* eslint-disable jsx-a11y/anchor-is-valid */
import '../../../../../../../../App.css'
import {toAbsoluteUrl} from '../../../../../../../../_ePulse/helpers'

export function Template2(props: any) {
  return (
    <div
      style={{
        width: '750px',
        margin: '0 auto',
        fontSize: '14px',
        fontFamily: "'Montserrat', sans-serif",
      }}
    >
      <div
        style={{
          background: 'linear-gradient(270deg, #29AAA9 0.09%, #564EA1 99.92%)',
          textAlign: 'center',
          margin: '0',
          width: '90%',
          borderRadius: '0 0 100px 0',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            height: 'calc(100% + 10px)',
            width: 'calc(100% + 10px)',
            background: 'rgba(0, 0, 0, 0.1)',
            // zIndex: '-1',
            borderRadius: '0 0 100px 0',
            top: '0',
            left: '0',
          }}
        ></div>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '10px',
            float: 'left',
            paddingLeft: '20px',
            borderBottom: '1px solid #fff',
            borderRight: '1px solid #fff',
            borderRadius: '0 0 20px 0',
          }}
        >
          <img
            style={{maxHeight: '24px'}}
            src={toAbsoluteUrl('/media/ePulse/logoInverse.svg')}
            alt='ePulse Logo'
          />
          <span style={{padding: '0 10px', color: '#ffffff'}}>Healthcare made simple</span>
        </div>
        <div style={{padding: '10px 20px', display: 'flex', width: '100%'}}>
          {/* <div
            className='doctor-Logo'
            style={{
              position: 'absolute',
              right: '-19px',
              top: '5%',
              background: 'rgba(255, 255, 255, 0.8)',
              borderRadius: '50%',
              padding: '14px',
              border: '1px solid #5452a2',
            }}
          >
            <img src='doctorLogo1.png' style={{maxHeight: '70px'}} alt='Doctor Logo' />
          </div> */}
          <div style={{lineHeight: '1.2', marginLeft: '1rem', color: '#fff', textAlign: 'left'}}>
            <span style={{fontSize: '18px'}}>Dr. Vijay Sankar Sharma</span>
            <br />
            <span className='label-4'>MBBS</span>
            <br />
            <span className='label-4'>Cardiologist</span>
            <br />
            <span className='label-4'>Registration no- 12341</span>
          </div>
        </div>
      </div>

      {/* <div style={{padding: '0 10px', marginTop: '1.5rem'}}>
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px', paddingRight: '15%'}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{minWidth: '60%', flex: 1}}>
              <span style={{fontWeight: 'bold'}}>Patient Details:</span> Name (Male / 37 Yrs /
              04/01/76)
            </div>
            <div style={{minWidth: '30%'}}>
              <span style={{fontWeight: 'bold'}}>Phone No</span> [0102030405]
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{minWidth: '60%', flex: 1}}>
              <span style={{fontWeight: 'bold'}}>Complaint </span> _______________
            </div>
            <div style={{minWidth: '30%'}}>
              <span style={{fontWeight: 'bold'}}>Diagnosis </span> _______________
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: '10px', minWidth: '50%'}}>
            <div>
              <span style={{fontWeight: 'bold'}}>Notes: </span>{' '}
              ___________________________________________________________________________
            </div>
          </div>
        </div>
      </div> */}

      <div className='patient-details' style={{padding: '0 10px', marginTop: '1.5rem'}}>
        <div className='patient-details-box'>
          <div>
            <span className='label'>Patient Name: </span> [Patient Name]
          </div>
          <div>
            <span className='label'>Patient Phone No: </span> [0102030405]
          </div>
          <div>
            <span className='label'>Consultatlon Type</span> Clinic Appointment
          </div>
        </div>
        <div className='patient-details-box'>
          <div>
            <span className='label'>Consultatlon Date</span> 26/10/2023
          </div>
          {/* <div>
              <span className='label'>Consultant</span> Dr. Vijay Pratap
            </div> */}
          <div>
            <span className='label'>Gender: </span> Male
          </div>
        </div>
      </div>

      <div style={{marginTop: '20px', padding: '0 10px'}}>
        <div
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            marginBottom: '4px',
            borderBottom: '1px solid #dddddd',
          }}
        >
          Vitals
        </div>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
          <div style={{flex: 1, minWidth: '50%'}}>
            <span style={{fontWeight: 'bold'}}>CVS: </span> 58743
          </div>
          <div style={{flex: 1, minWidth: '50%'}}>
            <span style={{fontWeight: 'bold'}}>Blood Pressure: </span> 120/80 mmHg
          </div>
          <div style={{flex: 1, minWidth: '50%'}}>
            <span style={{fontWeight: 'bold'}}>Blood Sugar: </span> 98 mg/dl
          </div>
        </div>
      </div>

      <div style={{marginTop: '20px', padding: '0 10px'}}>
        <div
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '10px',
            borderBottom: '1px solid #dddddd',
          }}
        >
          Rx
        </div>
        <table style={{width: '100%'}} cellPadding='0' cellSpacing='0'>
          <thead>
            <tr style={{fontWeight: 'bold'}}>
              <td
                style={{padding: '4px', verticalAlign: 'top', borderBottom: '1px solid #eee'}}
              ></td>
              <td style={{padding: '4px', verticalAlign: 'top', borderBottom: '1px solid #eee'}}>
                Medicine
              </td>
              <td style={{padding: '4px', verticalAlign: 'top', borderBottom: '1px solid #eee'}}>
                Dose
              </td>
              <td style={{padding: '4px', verticalAlign: 'top', borderBottom: '1px solid #eee'}}>
                Timing
              </td>
              <td style={{padding: '4px', verticalAlign: 'top', borderBottom: '1px solid #eee'}}>
                Duration
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{padding: '4px', verticalAlign: 'top'}}>1</td>
              <td style={{padding: '4px', verticalAlign: 'top'}}>
                <span style={{fontWeight: 'bold'}}>Dolo 50mg</span> <br />
                <span style={{fontSize: '12px'}}>(Paracetamol)</span>
              </td>
              <td style={{padding: '4px', verticalAlign: 'top'}}>0-0-1</td>
              <td style={{padding: '4px', verticalAlign: 'top'}}>After meal</td>
              <td style={{padding: '4px', verticalAlign: 'top'}}>5 days</td>
            </tr>
            <tr>
              <td style={{padding: '4px', verticalAlign: 'top'}}>2</td>
              <td style={{padding: '4px', verticalAlign: 'top'}}>
                <span style={{fontWeight: 'bold'}}>Omnacortil 10 MG Tablet</span> <br />
                <span style={{fontSize: '12px'}}>(Prednisolone)</span>
              </td>
              <td style={{padding: '4px', verticalAlign: 'top'}}>1-0-1</td>
              <td style={{padding: '4px', verticalAlign: 'top'}}>After meal</td>
              <td style={{padding: '4px', verticalAlign: 'top'}}>7 days</td>
            </tr>
            <tr>
              <td style={{padding: '4px', verticalAlign: 'top'}}>3</td>
              <td style={{padding: '4px', verticalAlign: 'top'}}>
                <span style={{fontWeight: 'bold'}}>Advil 10 MG Drops</span> <br />
                <span style={{fontSize: '12px'}}>(Ibuprofen)</span>
              </td>
              <td style={{padding: '4px', verticalAlign: 'top'}}>1-1-1</td>
              <td style={{padding: '4px', verticalAlign: 'top'}}>before meal</td>
              <td style={{padding: '4px', verticalAlign: 'top'}}>10 days</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style={{marginTop: '20px', padding: '0 10px'}}>
        <div
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            marginBottom: '4px',
            borderBottom: '1px solid #dddddd',
          }}
        >
          Next Visit
        </div>
        <div>21-Dec-2023, Tuesday</div>
      </div>

      <div
        style={{marginTop: '50px', textAlign: 'right', display: 'flex', flexDirection: 'column'}}
      >
        <div
          style={{
            backgroundColor: '#dddddd',
            height: '1px',
            width: '200px',
            marginLeft: 'auto',
            marginBottom: '5px',
          }}
        ></div>
        <div style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '4px'}}>Signed</div>
      </div>

      <div
        style={{
          padding: '0',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            background: '#93d0e5',
            padding: '10px 80px 10px 10px',
            borderRadius: '0 100px 0 0',
          }}
        >
          <span>
            <img
              src='/media/ePulse/phone_logo.svg'
              style={{maxHeight: '16px', marginRight: '3px', position: 'relative', top: '0px'}}
              alt='Phone Icon'
            />
            +91-1020304050
          </span>
          <br />
          <span>
            <img
              src='/media/ePulse/email_logo.svg'
              style={{maxHeight: '16px', marginRight: '3px', position: 'relative', top: '0px'}}
              alt='Email Icon'
            />
            vijay.sharma@getwell.com
          </span>
          <br />
          <span>
            <img
              src='/media/ePulse/website_icon.svg'
              style={{maxHeight: '16px', marginRight: '3px', position: 'relative', top: '0px'}}
              alt='website Icon'
            />
            myepulse.in
          </span>
        </div>
        <div
          style={{
            background: '#93d0e5',
            padding: '10px 10px 10px 80px',
            borderRadius: '100px 0 0 0',
          }}
        >
          <span style={{fontSize: '18px'}}>Dr. Vijay Sankar Sharma</span>
          <br />
          <span style={{fontSize: '16px'}}>C-Scheme, Jaipur</span>
        </div>
      </div>
    </div>
  )
}
