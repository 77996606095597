/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'

import {useQuery} from '@apollo/client'
import {GET_PATIENT_LIST_PAGINATION} from '../../../../app/MasterModules/DoctorModule/GraphQLQueries/Patients'
import {useNavigate} from 'react-router-dom'
import useLocalStorageFetch from '../../../../hooks/localStorageFetch'
import {LoadingSpinner} from '../../../layout/components/LoadingSpinner'
import useApi from '../../../../hooks/useQuery'
import {KTSVG} from '../../../helpers'
import {checkPermission} from '../../../../app/App'
import {EditPatientModal} from '../../../../app/MasterModules/DoctorModule/modules/importPatient/modals/EditPatient'
import {DeletePatientModal} from '../../../../app/MasterModules/DoctorModule/modules/importPatient/modals/DeletePatient'
import {ViewPatientModal} from '../../../../app/MasterModules/DoctorModule/modules/importPatient/modals/ViewPatient'

const PatientsQueue = () => {
  const navigate = useNavigate()
  const [editModal, seteditModal] = useState(false)
  const [deleteModal, setdeleteModal] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const {role} = useLocalStorageFetch()
  const [listing, setList] = useState([])
  const [selectedPatient, setSelectedPatient] = useState('')

  const {
    data,
    loading,
    error,
    refetch: refectch6,
  } = useApi(true, GET_PATIENT_LIST_PAGINATION, {page: 1, limit: 10})

  useEffect(() => {
    if (data) {
      setList(data?.getBusinessPatientPaginationList?.data)
    }
  }, [data, listing])

  const closeModal = () => {
    seteditModal(false)
    setdeleteModal(false)
    setViewModal(false)
    refectch6()
  }

  return (
    <div className='card card-xl-stretch mb-xl-8'>
      <LoadingSpinner isLoading={loading} />
      {/* begin::Header */}

      {editModal && (
        <EditPatientModal
          modalState={editModal}
          closeModal={() => closeModal()}
          idToEdit={selectedPatient}
        />
      )}

      {deleteModal && (
        <DeletePatientModal
          modalState={deleteModal}
          closeModal={() => closeModal()}
          idToEdit={selectedPatient}
        />
      )}
      {viewModal && (
        <ViewPatientModal
          modalState={viewModal}
          closeModal={() => closeModal()}
          idToEdit={selectedPatient}
        />
      )}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Patients Queue</span>
        </h3>
        <div
          className='card-toolbar'
          style={{cursor: 'pointer'}}
          onClick={() => {
            navigate(`/${role}/patients`)
          }}
        >
          View All
        </div>
      </div>

      <div className='card-body pt-5' style={{height: '250px', overflow: 'scroll'}}>
        {listing.length ? (
          <>
            <div className='d-flex align-items-center flex-row-fluid flex-wrap mb-3'>
              <div className='d-flex flex-row col justify-content-center'>
                <span className='  fs-6 fw-bold'>Patient Name</span>
              </div>
              <div className='d-flex flex-row col justify-content-center'>
                <span className='  fs-6 fw-bold'>Patient Mobile No.</span>
              </div>

              <div className='d-flex flex-row col justify-content-center'>
                <span className='  fs-6 fw-bold'>Gender</span>
              </div>

              <div className='d-flex flex-row col justify-content-center'>
                <span className='  fs-6 fw-bold'>Actions</span>
              </div>

              {/* <span className='badge badge-light fw-bold my-2 text-dark'>
                Patient mobile Number
              </span> */}
            </div>
            {listing.map((patient: any) => {
              return (
                <div className='d-flex align-items-center flex-row-fluid flex-wrap mb-3'>
                  <div className='d-flex flex-row col justify-content-center'>
                    <span className='text-gray-800  fs-6 fw-bold'>
                      {patient.firstName} {patient.lastName}
                    </span>
                  </div>

                  <div className='d-flex flex-row col justify-content-center'>
                    <span className='text-gray-800  fs-6 fw-bold'>{patient.mobileNumber}</span>
                  </div>

                  <div className='d-flex flex-row col justify-content-center'>
                    <span className='text-gray-800  fs-6 fw-bold'>{patient.gender}</span>
                  </div>

                  <div className='d-flex flex-row col justify-content-center'>
                    {/* <span className='text-gray-800  fs-6 fw-bold'>:</span> */}
                    <a
                      href='javascript:void(0);'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <KTSVG
                        path='/media/ePulse/dots_vertical_icon.svg'
                        className='svg-icon-2 m-0'
                      />
                    </a>
                    <div
                      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                      data-kt-menu='true'
                    >
                      {checkPermission('editBusinessPatient') && (
                        <div className='fld-ex align-items-center justify-content-center py-1'>
                          <div className='d-flex flex-row align-items-center justify-content-center'>
                            <button
                              onClick={() => {
                                setSelectedPatient(patient.userRoleId)
                                seteditModal(true)
                              }}
                              className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      )}
                      {checkPermission('deleteBusinessPatient') && (
                        <div className='fld-ex align-items-center justify-content-center py-1 border'>
                          <div className='d-flex flex-row align-items-center justify-content-center'>
                            <button
                              onClick={() => {
                                setSelectedPatient(patient.userRoleId)
                                setdeleteModal(true)
                              }}
                              className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      )}
                      {checkPermission('getBusinessUserProfile') && (
                        <div className='fld-ex align-items-center justify-content-center py-1 border'>
                          <div className='d-flex flex-row align-items-center justify-content-center'>
                            <button
                              onClick={() => {
                                setSelectedPatient(patient.userRoleId)
                                setViewModal(true)
                              }}
                              className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
                            >
                              View
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </>
        ) : (
          <div className='text-center fs-5 fw-semibold'>No data found</div>
        )}
      </div>
    </div>
  )
}

export {PatientsQueue}
